/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.top-image {
  min-width: 250px;
}

/* for material ui */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:hover,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  padding: 0.5rem 0.2rem !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-width: 0px !important;
  font-size: 14px !important;
  color: rgb(71, 71, 71) !important;
}

select {
  outline: none !important;
  border: none;
}

select:focus {
  outline: none !important;
}
